<script setup lang="ts" name="VoyageTracker">
import { computed } from 'vue';

import { VOYAGE_TRACKER_STEP_STATUS } from '~/constants';

import BaseCircle from '~/components/BaseCircle.vue';
import BaseItem from '~/components/BaseItem.vue';

import type {
  IVoyageTrackerStepPort,
  VoyageTrackerPadding,
  VoyageTrackerStepStatus,
  VoyageTrackerStepType,
} from '~/types';

const {
  stepStatus = 'default',
  stepType = 'primary',
  disabled,
  port,
  padding,
  number,
  loading,
} = defineProps<{
  stepStatus?: VoyageTrackerStepStatus;
  stepType?: VoyageTrackerStepType;
  port?: IVoyageTrackerStepPort;
  number?: number;
  loading?: boolean;
  padding?: VoyageTrackerPadding;
  disabled?: boolean;
  hideTopBorder?: boolean;
  hideBottomBorder?: boolean;
}>();

const circleSize = computed(() => (stepType === 'primary' ? 'md' : 'xs'));

const circleBackground = computed(() => {
  const activeOrInProgressStatuses = [
    VOYAGE_TRACKER_STEP_STATUS.ACTIVE,
    VOYAGE_TRACKER_STEP_STATUS.ACTIVE_IN_PROGRESS,
  ] as string[];

  if (activeOrInProgressStatuses.includes(stepStatus)) {
    return 'pulse';
  }

  if (stepStatus === VOYAGE_TRACKER_STEP_STATUS.COMPLETED) {
    return 'filled';
  }

  return 'transparent';
});

const circleBorder = computed(() =>
  stepStatus === VOYAGE_TRACKER_STEP_STATUS.UPCOMING ? 'dashed' : 'solid',
);

const circleIcon = computed(() =>
  stepStatus === VOYAGE_TRACKER_STEP_STATUS.COMPLETED
    ? 'ic:baseline-check'
    : null,
);

const circleNumber = computed(() =>
  stepStatus === VOYAGE_TRACKER_STEP_STATUS.DEFAULT ? number : undefined,
);

const borderStateClass = computed(() => ({
  'border-charcoal-4': disabled || loading,
  'border-secondary-3': !disabled && !loading,
}));

const topLineIsDashed = computed(() => {
  const statuses = [
    VOYAGE_TRACKER_STEP_STATUS.UPCOMING,
    VOYAGE_TRACKER_STEP_STATUS.DEFAULT,
  ] as string[];

  return statuses.includes(stepStatus);
});

const bottomLineIsDashed = computed(() => {
  const statuses = [
    VOYAGE_TRACKER_STEP_STATUS.UPCOMING,
    VOYAGE_TRACKER_STEP_STATUS.ACTIVE,
    VOYAGE_TRACKER_STEP_STATUS.DEFAULT,
  ] as string[];

  return statuses.includes(stepStatus);
});

const stepPadding = computed(() => {
  switch (padding) {
    case 'small':
      return 'py-1';
    case 'medium':
      return 'py-2';
    case 'large':
      return 'py-4';
    default:
      return '';
  }
});
</script>

<template>
  <div class="flex h-full gap-4">
    <div
      class="flex w-6 flex-col items-center"
      :class="{ 'animate-pulse': loading }"
    >
      <div
        class="grow border-l-2"
        :class="[
          borderStateClass,
          {
            'border-transparent': hideTopBorder,
            'border-dashed': topLineIsDashed,
          },
        ]"
      />

      <BaseCircle
        color="secondary"
        :size="circleSize"
        :background="circleBackground"
        :border="circleBorder"
        :icon="circleIcon"
        :number="circleNumber"
        :disabled="disabled || loading"
      />

      <div
        class="grow border-l-2"
        :class="[
          borderStateClass,
          {
            'border-transparent': hideBottomBorder,
            'border-dashed': bottomLineIsDashed,
          },
        ]"
      />
    </div>
    <div v-if="loading" class="flex-center py-4">
      <div class="text-loading h-3 w-36"></div>
    </div>

    <div v-else-if="port" class="flex items-center" :class="stepPadding">
      <BaseItem
        v-if="port.name && port.type"
        :label="port.type"
        :value="port.name"
        :swap="port.swap"
        :type="port.changed ? 'changed' : 'default'"
        :disabled="disabled"
      />
      <div v-else>
        <p
          v-if="port.name"
          :class="{
            '!text-charcoal-5': disabled,
            'text-subtitle-2': port.date && port.time && port.eventStatus,
            'text-body-1': !port.date && !port.time && !port.eventStatus,
          }"
        >
          {{ port.name }}
        </p>
        <div
          class="flex items-center gap-4"
          :class="{
            'text-charcoal-5': disabled,
            'text-body-1': !port.name,
            'text-body-2': port.name,
            'text-charcoal-6': port.name && !disabled,
          }"
        >
          <div class="flex w-[150px] justify-between">
            <p>{{ port.date }}</p>
            <p>{{ port.time }}</p>
          </div>
          <p>{{ port.eventStatus }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
